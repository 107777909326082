.App {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  z-index: 9;
}

header > div {
  width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header a {
  padding-left: 24px;
  font-size: 15pt;
  color: #333;
  text-decoration: none;
}

header a:hover {
  font-weight: bold;
  color: #339;
}

.logo {
  font-size: 32px;
  font-weight: bold;
  font-family: "Arial Black", Gadget, sans-serif
}

.title {
  font-size: 28pt;
  font-weight: bold;
  color: #333;
  padding-top: 12px;
  padding-bottom: 12px;
}

.desc {
  font-size: 16pt;
  line-height: 26pt;
  color: #666;
  padding-top: 12px;
  padding-bottom: 12px;
}

.contact {
  padding-top: 28px;
  padding-bottom: 12px;
}

.contact > a {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 36px;
  padding-right: 36px;
  font-size: 20px;
  background-color: #33C;
  color: white;
  border-width: 0;
  border-radius: 999px;
  text-decoration: none;
}

.contact > a:hover {
  opacity: 0.5;
}

.contact.white > a {
  background-color: white;
  color: #33C;
}

section {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

section#feature-2 {
  background-color: #339;
  color: white;
}

section#feature-2 .title,
section#feature-2 .desc {
  color: white;
}

section#pricing {
  background-color: #FAFAFA;
}

.content {
  width: 1000px;
  display: flex;
  align-items: center;
  align-self: center;
}

.content.col {
  display: flex;
  flex-direction: row;
}

.content.col > * {
  flex: 1;
}

.content.row {
  flex-direction: column;
  margin-top: 56px;
  margin-bottom: 64px;
}

.content.row > div {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.content.row > div.platforms {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
}

.content.row > div.platforms > div {
  height: 56px;
  padding-left: 24px;
  padding-right: 24px;
  border-right: 1px solid rgba(0,0,0,.1);
  display: flex;
  align-items: center;
}

.content.row > div.platforms > div:last-child {
  border-width: 0;
}

.topic {
  z-index: 9;
}

.image-wrapper {
  position: relative;
  z-index: 1;
}

.image-wrapper img {
  position: absolute;
}

.image-wrapper.shop {
  min-height: 420px;
}

.image-wrapper.shop img {
  bottom: 20px;
  left: -260px;
}

.image-wrapper.box {
  min-height: 450px;
}

.image-wrapper.box img {
  top: 0;
  right: -80px;
}

.card {
  background-color: white;
  box-shadow: 2px 2px 30px rgba(0,0,0,.1);
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 8px;
}

.card > div {
  border-right: 1px solid rgba(0,0,0,.1);
  flex: 1;
  padding: 24px;
}

.card > div:last-child {
  border-width: 0;
}

.plan-name {
  font-size: 26px;
  font-weight: bold;
}

.plan-price {
  font-size: 36px;
  font-weight: bold;
  padding-top: 16px;
  height: 48px;
}

.plan-price > span {
  font-size: 28px;
}

.plan-price > span.unit {
  font-size: 18px;
}

.plan-users {
  font-size: 16px;
  padding-top: 18px;
}

.plan-features {
  text-align: left;
  padding-top: 28px;
}

.plan-features > div {
  padding-top: 12px;
  display: flex;
  flex-direction: row;
}

.plan-features > div > div {
  padding-left: 16px;
}

footer {
  height: 200px;
  background-color: #333;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 48px;
}

footer .logo {
  padding-bottom: 16px;
}

footer > div {
  width: 1000px;
  display: flex;
  justify-content: space-between;
}

footer .info > .logo {
  padding-bottom: 16px;
}

footer .info > div {
  padding-bottom: 10px;
}

footer .info a {
  color: white;
}

footer .more {
  display: flex;
  flex-direction: row;
}

footer .list {
  display: flex;
  flex-direction: column;
  padding-left: 48px;
}

footer .list > div {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 16px;
}

footer .list > a {
  color: white;
  text-decoration: none;
  padding-bottom: 12px;
}

footer .list > a:hover {
  opacity: 0.5;
}
